import React from 'react';
import {
  Stack, Typography, CircularProgress, List, ListItem,
} from '@mui/joy';
import { LandCoverClassesResponse } from 'api/openapi/generated/RawApi';

interface LandCoverListProps {
  landCover: LandCoverClassesResponse | undefined;
  isLandCoverLoading: boolean;
}

const LandCoverList: React.FC<LandCoverListProps> = ({ landCover, isLandCoverLoading }) => (
  <Stack
    sx={{
      position: 'absolute',
      bottom: 50,
      left: 10,
      zIndex: 20,
      padding: 1,
      borderRadius: '4px',
      boxShadow: 1,
      backgroundColor: 'Background',
    }}
  >
    <Typography
      textAlign="center"
      level="body-sm"
      sx={{
        borderBottom: '1px solid lightGrey',
        color: 'InfoText',
      }}
    >
      Land cover classes
    </Typography>
    {isLandCoverLoading && <CircularProgress size="sm" />}
    <List sx={{ width: 'fit-content' }}>
      {landCover?.classes
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label)) // Sort classes alphabetically by label
        .map((cls) => (
          <ListItem
            key={cls.classId}
            sx={{
              justifyContent: 'space-between',
              gap: 5,
            }}
          >
            <Typography level="body-sm">{cls.label}</Typography>

            <Typography
              sx={{
                backgroundColor: cls.color,
                borderRadius: '5px',
                width: '15px',
                height: '15px',
                border: '1px solid #aaa',
              }}
            />
          </ListItem>
        ))}

    </List>
  </Stack>
);

export default LandCoverList;
